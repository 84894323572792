export const checkIsBalti = (): boolean => {
  return process.env.REACT_APP_BRANCH_NAME === "balti";
};

export const changeBaltiMeta = (): void => {
  const favicon = document.querySelector('link[rel="icon"]');
  if (!favicon) return;
  favicon.setAttribute("href", "/favicon-balti.ico");
  document.title = "E-gradinita Balti";
};
